export default {
    nav: {
        main: {
            assets: 'Master data',
            orchestrator: 'Orchestrator',
        },
        assets: {
            users: 'Users',
            globalValues: 'Settings',
            globalFiles: 'Special files',
            emailTemplates: 'Email templates',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        orchestrator: {
            orchestratorOverview: 'Orchestrator Overview',
            fileOverview: 'File Overview',
            requestOverview: 'Request Overview',
            annotationOverview: 'Annotation Overview',
            endpointOverview: 'Endpoints',
        },
    },
    orchestrator: {
        overview: {
            title: 'Orchestrator Overview',
        },
        edit: {
            title: 'Edit Orchestrator',
            endpointUrl: 'Endpoint Url',
        },
        field: {
            id: 'ID',
            createdAt: 'Created At',
            endpointUrl: 'Endpoint Url',
        },
        button: {
            edit: 'Edit',
            delete: 'Delete',
        },
    },
    endpoint: {
        overview: {
            title: 'Endpoint Overview',
        },
        edit: {
            title: 'Edit Endpoint',
            targetUrl: 'Endpoint Url',
        },
        field: {
            id: 'ID',
            createdAt: 'Created At',
            targetUrl: 'Endpoint Url',
        },
        button: {
            edit: 'Edit',
            delete: 'Delete',
        },
    },
    orchestratorRequest: {
        overview: {
            title: 'Request Overview',
        },
        edit: {
            title: 'Edit Request'
        },
        field: {
            id: 'ID',
            status: 'Status',
            createdAt: 'Created At',
            processedAt: 'Processed At',
            file: 'File',
        },
        create: {
            title: 'Upload File',
            orchestrator: 'Orchestrator',
            file: 'File',
        },
        button: {
            retry: 'Resend',
            edit: 'Edit',
            download: 'Download',
            delete: 'Delete',
        }
    },
    file: {
        overview: {
            title: 'File Overview',
        },
        field: {
            id: 'ID',
            status: 'Status',
            createdAt: 'Created At',
            file: 'File',
        },
        create: {
            title: 'Upload File',
            orchestrator: 'Orchestrator',
        },
        edit: {
            title: 'Edit File',
        },
        button: {
            edit: 'Edit',
            download: 'Download'
        }
    },
    annotation: {
        overview: {
            title: 'Annotation Overview',
        },
        field: {
            id: 'ID',
            createdAt: 'Created At',
        },
    },
    modal: {
        pdf: {
            loading: 'Loading',
            error: 'Error',
            nextPage: '>',
            previousPage: '<',
        },
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
            mfa: {
                label: 'MFA methods',
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
        multiFactorAuth: {
            code: {
                app: 'Verification code',
                email: 'Login code',
                newCode: 'Request new code',
            },
            expiring: 'Your authentication is still valid for {{ minutes }} minutes',
            explanation: {
                app: 'Enter the code that your authenticator app generated.',
                email: 'Your login code has been sent by email.',
            },
            explanationFailed: 'We tried to send a login code to the registered email address, but failed.',
            timeout: 'You tried too many times. You can try again in a few minutes.',
            invalid: {
                app: 'The verification code is incorrect or has expired. Please try again.',
                email: 'The login code is incorrect or has expired. Please try again or request a new code.',
            },
            resend: 'Resend',
            title: 'Confirm',
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    filters: {
        deletedFilter: {
            label: 'Deleted',
            withoutDeleted: 'Without deleted',
            onlyDeleted: 'Only deleted',
            bothDeletedAndNot: 'Both'
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore: 'Restore',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        no: 'No',
        yes: 'Yes',
        either: 'Either',
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    emailTemplate: {
        overview: {
            title: 'Email templates',
            addButton: 'Add email template',
        },
        create: { title: 'Create mail template' },
        edit: { title: 'Edit mail template' },
        add: { title: 'Add mail template' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is default' },
            name: { label: 'Name' },
            emailType: { label: 'Email type' },
            subject: { label: 'Subject' },
            textContent: { label: 'Text variant of email', placeholder: 'Some mail clients only support text' },
            senderEmail: { label: 'Sender email' },
        }
    },
    emailTypes: {
        password_reset: 'Password reset',
        user_activation: 'User activation',
    },
    authentication: {
        dates: {
            maxDate: {
                placeholder: 'Max date',
            },
            minDate: {
                placeholder: 'Min date',
            },
            month: {
                0: 'January',
                1: 'February',
                10: 'November',
                11: 'December',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October',
            },
            weekDayShort: {
                friday: 'Fr',
                monday: 'Mo',
                saturday: 'Sa',
                sunday: 'Su',
                thursday: 'Th',
                tuesday: 'Tu',
                wednesday: 'We',
            }
        },
        field: {
            mfa: {
                label: 'Method',
            },
            code: {
                label: 'Code',
            },
            expiresAt: {
                label: 'Expired',
            },
            ipAddress: {
                label: 'IP address',
            },
            status: {
                label: 'Status',
                value: {
                    expired: 'Expired',
                    verified: 'Verified',
                    waiting: 'Pending',
                }
            },
            userAgent: {
                label: 'User Agent',
            }
        }
    },
    multiFactorAuthentication: {
        setup: {
            title: 'Set up multi-factor authentication',
            step1: {
                title: '1. Download the app',
                description: 'Download an authenticator app, such as Google Authenticator, if you don\'t have one already.',
            },
            step2: {
                title: '2. Scan the QR code',
                description: 'Scan this QR code with your authenticator app.',
            },
            step3: {
                title: '3. Enter the code',
                description: 'Enter the code you see in your authenticator app.',
            },
            subtitle: 'After setup, you can always log in securely using your authenticator app.',
        },
        field: {
            method: {
                label: 'MFA method',
                options: {
                    app: 'Authenticator app',
                    email: 'Email',
                }
            },
            counter: {
                label: 'Uses',
            },
            activated: {
                label: 'Verified',
            },
        }
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Theta Vision',
};
