import { tint } from 'polished';
import { IS_STAGE, IS_UAT } from 'helpers';

// Change the colors for your personal preference
const COLOR_PRIMARY_PROD = '#2e78d2';
const COLOR_PRIMARY_STAGE = '#3b382d';
const COLOR_PRIMARY_UAT = '#4d303b';

const getPrimaryColor = (): string => {
    if (IS_STAGE) return COLOR_PRIMARY_STAGE;
    if (IS_UAT) return COLOR_PRIMARY_UAT;
    return COLOR_PRIMARY_PROD;
};

const primaryColor = getPrimaryColor();
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#286db7';
const dangerColor = '#dc0818';
const warningColor = '#eb9741';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
};
